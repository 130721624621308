'use client';
import Image from 'next/image';
import './Impact.scss';
import { impact } from '@/utils/data';
import CountUp from 'react-countup';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatistics, selectStatistics } from '@/slice/statisticsSlice';
import { useEffect } from 'react';

export default function Impact({ data }) {
  const intl = useIntl();

  const dispatch = useDispatch();
  const statistics = useSelector(selectStatistics);
  const status = useSelector((state) => state.statistics.status);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchStatistics());
    }
  }, [status, dispatch, data]);

  function updateData(originalData, data) {
    return originalData.map((item) => {
      if (data[item.key] !== undefined) {
        return {
          ...item,
          count: data[item.key],
        };
      }
      return item;
    });
  }

  const displayData = statistics?.data
    ? updateData(impact, statistics.data)
    : null;

  return (
    <>
      <div className="impactWrapper">
        <div className="impactContainer">
          <p className="heading">
            {intl.formatMessage({ id: 'impactAndReach' })}
            <span className="highlightHeading"> </span>
          </p>

          <div className="infoWrapper">
            {displayData &&
                            displayData?.map((item, i) => {
                              return (
                                <div className="infoContainer" key={i}>
                                  <Image
                                    loading="lazy"
                                    src={item.icon}
                                    alt={item.name}
                                    width={0}
                                    height={0}
                                    className="impactIconContainer"
                                  />

                                  <p className="count">
                                    <CountUp
                                      end={item.count}
                                      duration="3"
                                      decimals={
                                        item.id == 1 || item.id == 3
                                          ? '1'
                                          : '0'
                                      }
                                      suffix={item.number}
                                    />
                                  </p>
                                  <p className="name">
                                    {intl.formatMessage({
                                      id: item.name,
                                    })}
                                  </p>
                                </div>
                              );
                            })}
          </div>
        </div>
      </div>
    </>
  );
}
