import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchAssistanceData = createAsyncThunk(
  'assistances/fetchAssistances',
  async ({ country, city, locale = 'en' } = {}) => {
    let url = `assistances?populate=*&locale=${locale}`;

    if (country) {
      url += `&filters[country][name][$eq]=${country}`;
    }
    if (city) {
      url += `&filters[city][name][$eq]=${city}`;
    }

    const response = await axios.get(url);
    return response.data;
  }
);

const assistancesSlice = createSlice({
  name: 'assistances',
  initialState: {
    assistances: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssistanceData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAssistanceData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assistances = action.payload;
      })
      .addCase(fetchAssistanceData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default assistancesSlice.reducer;

export const selectAllAssistances = (state) => state.assistances.assistances;
