import Link from 'next/link';
import './videoCard.scss';
import Image from 'next/image';
import { truncateText } from '@/utils/truncateText';

export default function VideoCard({ video }) {
  return (
    <Link href={`/how-its-work/${video?.slug}`}>
      <div className="videoCardWrapper">
        <div className="videoImgContainer">
          <Image
            src={video?.img?.url}
            alt="video background"
            fill
            priority
            style={{ objectFit: 'cover' }}
          />
        </div>
        {video?.title && (
          <p className="videoHeading">
            {truncateText(video?.title || video?.descrption, 26)}
          </p>
        )}
      </div>
    </Link>
  );
}
