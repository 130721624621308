import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchAssistanceDetails = createAsyncThunk(
  'assistances/fetchAssistances',
  async (slug) => {
    const response = await axios.get(
      `assistances?populate=*&filters[slug][$eq]=${slug}`
    );
    return response.data;
  }
);
const assistancesSlice = createSlice({
  name: 'assistances',
  initialState: {
    assistances: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssistanceDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAssistanceDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assistances = action.payload;
      })
      .addCase(fetchAssistanceDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default assistancesSlice.reducer;

export const selectAssistancesDetails = (state) =>
  state.assistantDetails.assistances;
