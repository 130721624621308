'use client';
import axios from 'axios';
const instance = axios.create({
  baseURL: '/api/',
  timeout: 10000,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: process.env.NEXT_PUBLIC_STRAPI_KEY,
  },
});

export default instance;
