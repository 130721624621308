'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchBlogBySlug = createAsyncThunk(
  'blogDetails/fetchBlogBySlug',
  async ({ slug, locale = 'en' }) => {
    const queryString = `blogs?populate=*locale=${locale}&filters[slug]=${slug}`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

const initialState = {
  blog: null,
  status: 'idle',
  error: null,
};

const blogDetailsSlice = createSlice({
  name: 'blogDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogBySlug.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogBySlug.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blog = action.payload;
      })
      .addCase(fetchBlogBySlug.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default blogDetailsSlice.reducer;

export const selectBlog = (state) => state.blogDetails.blog;
