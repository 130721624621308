'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchTreatmentBannerDetailsBySlug = createAsyncThunk(
  'treatmentBannerDetailsWithSlug/fetchTreatmentBannerDetailsBySlug',
  async ({ slug }) => {
    const queryString = `treatment-banner-details-treatments?filters[slug][$eq]=${slug}&populate=*`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

const initialState = {
  treatmentDetails: null,
  status: 'idle',
  error: null,
};

const treatmentBannerDetailsWithSlugSlice = createSlice({
  name: 'treatmentBannerDetailsWithSlug',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentBannerDetailsBySlug.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchTreatmentBannerDetailsBySlug.fulfilled,
        (state, action) => {
          state.status = 'succeeded';
          state.treatmentDetails = action.payload;
        }
      )
      .addCase(
        fetchTreatmentBannerDetailsBySlug.rejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        }
      );
  },
});

export default treatmentBannerDetailsWithSlugSlice.reducer;

export const selectAllTreatmentDetails = (state) =>
  state.treatmentBannerDetailsWithSlug.treatmentDetails;
export const selectTreatmentDetailsStatus = (state) =>
  state.treatmentBannerDetailsWithSlug.status;
export const selectTreatmentDetailsError = (state) =>
  state.treatmentBannerDetailsWithSlug.error;
