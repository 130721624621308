'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

// Create an async thunk for fetching the success story by slug
export const fetchSuccessStoryBySlug = createAsyncThunk(
  'successStoryDetails/fetchSuccessStoryBySlug',
  async ({ slug, locale = 'en' }) => {
    // Construct the query string with the appropriate parameters
    const queryString = `success-stories?populate=*&locale=${locale}&filters[slug]=${slug}`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

// Define the initial state for the slice
const initialState = {
  successStory: null,
  status: 'idle',
  error: null,
};

// Create the slice using createSlice
const successStoryDetailsSlice = createSlice({
  name: 'successStoryDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuccessStoryBySlug.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSuccessStoryBySlug.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.successStory = action.payload;
      })
      .addCase(fetchSuccessStoryBySlug.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the reducer as the default export
export default successStoryDetailsSlice.reducer;

// Selector to get the success story from the state
export const selectSuccessStory = (state) =>
  state.successStoryDetails.successStory;
