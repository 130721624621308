import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

export const fetchFitnessBlogBySlug = createAsyncThunk(
  'fitnessBlogs/fetchFitnessBlogBySlug',
  async ({ slug, locale = 'en' }) => {
    const queryString = `fitness-blogs?populate=*&locale=${locale}&filters[slug]=${slug}`;
    const response = await axios.get(queryString);
    return response.data;
  }
);


const initialState = {
  fitnessBlog: null,
  status: 'idle',
  error: null,
};

const fitnessBlogsSlice = createSlice({
  name: 'fitnessBlogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFitnessBlogBySlug.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFitnessBlogBySlug.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fitnessBlog = action.payload;
      })
      .addCase(fetchFitnessBlogBySlug.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default fitnessBlogsSlice.reducer;

export const selectFitnessBlog = (state) => state.fitnessBlogsSliceBySlug.fitnessBlog;
