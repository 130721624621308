'use client';
import CustomCarousel from '@/components/CustomCarousel/CustomCarousel';
import './informationalVideos.scss';
import VideoCard from './VideoCard/VideoCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHowItWorks, selectAllHowItWorks } from '@/slice/howItsWorkSlice';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function InformationalVideos({ itemsPerPage, data }) {
  const dispatch = useDispatch();
  const howItWorksData = useSelector(selectAllHowItWorks);
  const intl = useIntl();
  const lang = useSelector((state) => state.locale.locale);
  useEffect(() => {
    !data && dispatch(fetchHowItWorks(lang));
  }, [lang]);

  const howItWorks = data?.data || howItWorksData;

  return (
    <div className="infoVideosBg">
      <div className="infoVideosWrapper">
        <p className="infoVideoHeading">
          {intl.formatMessage({ id: 'howIt' })}{' '}
          <span className="highlight">
            {intl.formatMessage({ id: 'works' })}
          </span>
        </p>
        {howItWorks?.length > 0 && (
          <CustomCarousel itemsPerPage={itemsPerPage}>
            {howItWorks?.map((video, i) => (
              <VideoCard key={i} video={video} />
            ))}
          </CustomCarousel>
        )}
      </div>
    </div>
  );
}
