import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchTreatmentDetails = createAsyncThunk(
  'treatmentDetails/fetchTreatmentDetails',
  async ({ slug, locale = 'en' }) => {
    const queryString = `treatment-banners?filters[link][$eq]=${slug}&populate=img&locale=${locale}&populate=*`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

const initialState = {
  treatmentDetails: [],
  status: 'idle',
  error: null,
};

const treatmentBannerDetailsSlice = createSlice({
  name: 'treatmentDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTreatmentDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.treatmentDetails = action.payload;
      })
      .addCase(fetchTreatmentDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default treatmentBannerDetailsSlice.reducer;

export const selectAllTreatmentDetails = (state) =>
  state.treatmentBannerDetails.treatmentDetails;
export const selectTreatmentDetailsStatus = (state) =>
  state.treatmentBannerDetails.status;
export const selectTreatmentDetailsError = (state) =>
  state.treatmentBannerDetails.error;
