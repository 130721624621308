'use client';
import './AppBanner.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useIntl } from 'react-intl';

export default function AppBanner() {
  const intl = useIntl();

  return (
    <div className="appBannerWrapper">
      <div className="appBannerContainer">
        <div className="infoContainer">
          <p className="heading">
            {intl.formatMessage({ id: 'accessHeading' })}
          </p>
          <p className="subheading">
            {intl.formatMessage({ id: 'accessSubheading' })}
          </p>
          <div className="iconContainer">
            <Link href="https://apps.apple.com/in/app/fitsib-business/id6702030190">
              <Image
                loading="lazy"
                src="/images/appStore.svg"
                alt="App Store"
                width={0}
                height={0}
                className="storeIcons"
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.fitsib_business">
              <Image
                loading="lazy"
                src="/images/playStore.svg"
                alt="Play Store"
                width={0}
                height={0}
                className="storeIcons"
              />
            </Link>
          </div>
        </div>
        <Image
          loading="lazy"
          src="/images/mobile.svg"
          alt="Mobile Phone"
          width={0}
          height={0}
          className="mobile"
        />
      </div>
    </div>
  );
}
