import { routeName } from '@/utils/commonfuntions';
import './treatmentPackageCard.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useIntl } from 'react-intl';
export default function TreatmentPackageCard({ packageDetails }) {
  const intl = useIntl();
  return (
    <Link
      href={{
        pathname: `/packages/${routeName(packageDetails.slug)}`,
      }}
    >
      <div className="packageContainer">
        <div className="packageImgContainer">
          <Image
            loading="lazy"
            src={packageDetails?.img?.url}
            alt="package image"
            fill
            style={{ objectFit: 'cover' }}
          />
          <div className="journey">
            <p className="m-auto">{packageDetails?.country_from}</p>
            <Image
              loading="lazy"
              src="/images/arrowRight.svg"
              alt="arrow right"
              width={22}
              height={7}
            />
            <p className="m-auto">{packageDetails?.country_to}</p>
          </div>
        </div>
        <div className="packageInfoContainer">
          <p className="packageName">{packageDetails?.packageName}</p>

          <div className="durationsContainer">
            <div>
              <p className="heading">
                {intl.formatMessage({ id: 'flight' })}
              </p>
              <p className="subHeading">
                {packageDetails?.flightDuration}
              </p>
            </div>
            <div>
              <p className="heading">
                {intl.formatMessage({ id: 'duration' })}
              </p>
              <p className="subHeading">
                {packageDetails?.duration}
              </p>
            </div>
          </div>
          <div>
            <p className="heading">
              {intl.formatMessage({
                id: 'hospital',
              })}
            </p>
            <p className="subHeading">
              {packageDetails?.hospitalName}
            </p>
          </div>
          <div className="devider"></div>
          <p className="packageCost">
            {intl.formatMessage({ id: 'package_starting_from' })}{' '}
            <span className="highlight">
                            ${packageDetails?.packageCost}
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
}
