'use client';
import Link from 'next/link';
import './serviceCard.scss';
import Image from 'next/image';
import { routeName } from '@/utils/commonfuntions';
import { useState } from 'react';
import Modal from '@/components/Modal/Modal';
export default function ServiceCard({ service }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      // href={`${service?.link}`}
      className="serviceContainer"
      style={{
        background: 'e3f8ff82',

        // height: '250px',
        // maxHeight: service["id"] == 3 || service["id"] == 4 ? "250px" : "200px",
      }}
    >
      <div className="serviceImgContainer">
        <Image
          src={service?.img?.url}
          alt="care screen banner background"
          fill
          style={{ objectFit: 'cover' }}
          sizes="auto"
          loading="lazy"
          className="h-[220px] w-[220px]"
        />
      </div>
      <div className="serviceInfoContainer">
        <p className={'serviceNameType2'}>{service?.name}</p>
        <p className="heading">{service?.headline}</p>
        <p className="subHeading">{service?.descrption}</p>

        <div className="serviceBtnContainer">
          {service?.link ? (
            <Link
              href={`/treatments/${service?.link}`}
              className="serviceBtn"
            >
              {service?.cto_button_text}
            </Link>
          ) : (
            <p className="serviceBtn" onClick={openModal}>
              {service?.cto_button_text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
