'use client';
import './careScreenBanner.scss';
import Image from 'next/image';
import { countryData } from '@/utils/data';
import Marquee from 'react-fast-marquee';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  searchTopDoctors,
  searchTopHospitals,
  searchTreatments,
} from '@/slice/searchSlice';
import { debounce } from '@/utils/debounce';
import Link from 'next/link';
import { routeName } from '@/utils/commonfuntions';

export default function CareScreenBanner() {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const doctors = useSelector((state) => state.search.doctors);
  const hospitals = useSelector((state) => state.search.hospitals);
  const treatments = useSelector((state) => state.search.treatments);

  const handleSearch =
        (debounce((query) => {
          if (query) {
            dispatch(searchTopDoctors(query));
            dispatch(searchTopHospitals(query));
            dispatch(searchTreatments(query));
          }
        }, 500),
        []);

  const handleInputChange = (event) => {
    handleSearch(event.target.value);
  };

  const intl = useIntl();

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModal(false);
    }
  };

  useEffect(() => {
    if (modal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modal]);
  return (
    <div className="careBannerWrapper">
      <div className="careBannerInfoContainer">
        <div className="careBannerInfo">
          <p className="heading1">
            {intl.formatMessage({ id: 'heading1' })}
          </p>
          <p className="heading2">
            {intl.formatMessage({ id: 'heading2' })}
          </p>
          <p className="heading3">
            <h4>{intl.formatMessage({ id: 'heading3' })}</h4>

            <span className="ml-1 heading4">
              <h1>{intl.formatMessage({ id: 'heading4' })}</h1>
            </span>
          </p>
        </div>
        <div className="careBannerSearchWrapper">
          <p className="heading5">
            {intl.formatMessage({ id: 'heading5' })}
          </p>
          <div className="searchContainer">
            <div className="searchInputContainer">
              <input
                onClick={() => setModal(true)}
                onChange={handleInputChange}
                className="searchInput"
                type="text"
                name="searchCare"
                placeholder={intl.formatMessage({
                  id: 'searchPlaceholder',
                })}
              />
              {modal && (
                <div
                  ref={modalRef}
                  className="max-w-[500px] z-50 mt-5 w-full absolute bg-white flex gap-4 p-2 flex-col  min-h-[70vh]  max-h-[70vh] overflow-y-scroll"
                >
                  <div className="flex flex-col gap-2 resultsContainer">
                    <h3 className="p-2 bg-gray-100">
                                            Doctors
                    </h3>
                    {doctors.status === 'loading' && (
                      <p>
                        {intl.formatMessage({
                          id: 'modalLoading',
                        })}
                      </p>
                    )}
                    {doctors.status === 'succeeded' &&
                                            doctors?.data?.length > 0 &&
                                            doctors.data.map((doctor) => (
                                              <Link
                                                key={doctor?.name}
                                                href={{
                                                  pathname: `/doctors/${routeName(
                                                    doctor?.slug
                                                  )}`,
                                                }}
                                              >
                                                <p
                                                  className="hover:bg-gray-100"
                                                  key={doctor.id}
                                                >
                                                  {doctor?.name}
                                                </p>
                                              </Link>
                                            ))}

                    <h3 className="p-2 bg-gray-100">
                                            Treatments
                    </h3>

                    {treatments.status === 'succeeded' &&
                                            treatments?.data?.length > 0 &&
                                            treatments.data.map((treatment) => (
                                              <p
                                                className="hover:bg-gray-100"
                                                key={treatment.id}
                                              >
                                                {treatment?.name}
                                              </p>
                                            ))}
                    <h3 className="p-2 bg-gray-100">
                                            Hospitals
                    </h3>

                    {hospitals.status === 'succeeded' &&
                                            hospitals?.data?.length > 0 &&
                                            hospitals.data.map((hospital) => (
                                              <Link
                                                key={hospital?.name}
                                                href={{
                                                  pathname: `/hospitals/${routeName(
                                                    hospital?.slug
                                                  )}`,
                                                }}
                                              >
                                                <p
                                                  className="hover:bg-gray-100"
                                                  key={hospital.id}
                                                >
                                                  {hospital?.name}
                                                </p>
                                              </Link>
                                            ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <p className="heading6">
            {intl.formatMessage({
              id: 'heading6',
            })}
          </p>
        </div>

        <div>
          <Image
            loading="lazy"
            src="/images/deviderLine.svg"
            alt="devide line icon"
            width={0}
            height={0}
            style={{
              width: '100%',
              height: 'auto',
              margin: '20px 0px',
            }}
          />
        </div>

        <Marquee className="countriesContainer" speed={20}>
          {countryData.map((country, index) => (
            <div
              key={index}
              className="flex items-center gap-4 text-xs font-normal text-[#454f66] tracking-[.48px] border border-dashed border-[#e2e5ed] rounded-[19px] p-[6px] min-w-[115px] mx-[3px]"
            >
              <Image
                loading="lazy"
                src={`/images/${country.name.toLowerCase()}.svg`}
                alt="icon of country"
                width={25}
                height={25}
              />
              <p className="my-auto">{country.name}</p>
            </div>
          ))}
        </Marquee>
      </div>
      <div className="careBannerBg">
        <Image
          src="/images/careScreenBg.png"
          alt="care screen banner background"
          // fill
          height={484}
          width={474}
          priority
          // loading="lazy"
          // sizes="auto"
          layout="responsive"
          className="w-[90%] h-full object-cover"
        />
      </div>
    </div>
  );
}
