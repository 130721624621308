'use client';

import { useEffect } from 'react';
import { Provider } from 'react-redux';
import LanguageWrapper from '@/components/LanguageWrapper';
import store from '../../store';
import { Toaster } from 'react-hot-toast';
import { SkeletonTheme } from 'react-loading-skeleton';
import { getToken } from 'firebase/messaging';
import axios from 'axios';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import Script from 'next/script';
import mixpanel from 'mixpanel-browser';
import { messaging } from '../../firebase';
import './globals.scss';

import { isFirebaseSupported } from '@/utils/isFirebaseSupported';
import Bowser from 'bowser';
import { usePathname } from 'next/navigation';
import { GoogleTagManager } from '@next/third-parties/google';

export default function RootLayout({ children }) {
  const isUnsupportedBrowser = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName().toLowerCase();
    const platform = browser.getOSName().toLowerCase(); // Get platform type

    // List of unsupported browsers
    const unsupportedBrowsers = ['opera mini', 'internet explorer'];
    const isUnsupported =
            unsupportedBrowsers.includes(browserName) ||
            browser.getOS().name.toLowerCase() === 'macos' ||
            browser.getOS().name.toLowerCase() === 'ios' ||
            platform === 'ios' ||
            platform === 'macos';

    return isUnsupported;
  };

  useEffect(() => {
    // Register service worker for Firebase messaging
    if ('serviceWorker' in navigator && !isUnsupportedBrowser()) {
      window.addEventListener('load', async () => {
        try {
          await navigator.serviceWorker.register(
            '/firebase-messaging-sw.js'
          );
        } catch (error) {
          console.error('Service worker registration failed:', error);
        }
      });
    }
  }, []);

  // Request notification permission and retrieve Firebase token
  async function requestPermission() {
    if ('serviceWorker' in navigator && 'indexedDB' in window) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        try {
          const token = await getToken(messaging, {
            vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
          });
          await sendNotification(token);
        } catch (error) {
          console.error('Error fetching Firebase token:', error);
        }
      }
    }
  }

  // Send notification with FCM token
  async function sendNotification(fcmToken) {
    try {
      const response = await axios.post('notifications', {
        data: { fcm_token: fcmToken },
      });
      return response.data;
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  }

  useEffect(() => {
    isFirebaseSupported() && !isUnsupportedBrowser() && requestPermission();
  }, []);

  // Update link for consent management
  useEffect(() => {
    const updateConsentLink = () => {
      const link = document.getElementById('zpconsent-manage');
      if (link) {
        link.href = '/'; // Change to a valid URL if needed
        link.onclick = (e) => {
          e.preventDefault(); // Prevent default action
          // Ensure _zcBan is available before calling
          // if (typeof _zcBan !== 'undefined') {
          //   _zcBan.accept_consent();
          // }
        };
      }
    };

    const klaroScript = document.querySelector(
      'script[src="https://cdn.kiprotect.com/klaro/v0.7/klaro.js"]'
    );

    // Check if Klaro has loaded
    if (klaroScript) {
      klaroScript.onload = () => {
        setTimeout(updateConsentLink, 100); // Delay to ensure elements are rendered
      };
    } else {
      // If script is not found, attempt to call directly
      setTimeout(updateConsentLink, 100);
    }
  }, []);

  // Initialize Mixpanel
  useEffect(() => {
    mixpanel.init('5035a77d3325828539b133277fa450ab', {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }, []);

  const currPath = usePathname();
  return (
    <html lang="en">
      <head>
        <GoogleTagManager gtmId="GTM-WR5F2F86" />
        <link
          rel="canonical"
          href={`https://www.fitsib.com${currPath}`}
        />
        <meta
          name="msvalidate.01"
          content="08C8EED255BD3735970C7AD2DD344261"
        />

        <Script
          id="zoho-salesiq"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
      window.$zoho=window.$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {ready:function(){}};
    `,
          }}
        />
        <Script
          id="zsiqscript"
          src="https://salesiq.zohopublic.com/widget?wc=siq312ca5f6a317894bfb988c446f21f83adac25adf6a17119e6781f01502c261a5"
          async
          defer
          strategy="afterInteractive"
        />

        {/* Google Analytics */}
        <Script
          async
          strategy="lazyOnload"
          src="https://www.googletagmanager.com/gtag/js?id=G-378343ZK4F"
        />
        <Script
          id="google-analytics"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-378343ZK4F');
            `,
          }}
        />
        {/* Klaro Consent Management Script */}
        <Script
          defer
          strategy="lazyOnload"
          src="https://cdn.kiprotect.com/klaro/v0.7/klaro.js"
        />
        {/* Other tracking scripts */}
        <Script
          defer
          strategy="lazyOnload"
          src="https://cdn.pagesense.io/js/fitsib1/a3151de59c2446b2a8f85690fba6beed.js"
        />

        <link rel="icon" href="/favicon.ico" sizes="any" />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Fitsib - Your Gateway to World-Class Medical Treatment Abroad"
        />
        <meta
          property="og:description"
          content="Discover affordable medical treatments abroad with Fitsib. Compare hospitals, treatments, and doctors worldwide."
        />
        <meta
          name="description"
          content="Discover affordable medical treatments abroad with Fitsib. Compare hospitals, treatments, and doctors worldwide."
        />
        <meta
          property="og:image"
          content="https://d2fj4tw1j25ivs.cloudfront.net/assets/logos/logo.jpg"
        />
        <meta property="og:url" content="https://www.fitsib.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Find Affordable Quality Care Abroad | Fitsib</title>
        <link rel="preconnect" href="https://fitsib.in" />
        <link rel="preconnect" href="https://uat-cms.fitsib.in" />
        <link rel="dns-prefetch" href="https://fitsib.in" />
        <link rel="dns-prefetch" href="https://uat-cms.fitsib.in" />
        <link rel="preconnect" href="https://uat.fitsib.in" />
        <link rel="preconnect" href="https://uat-cms.fitsib.in" />
        <link rel="dns-prefetch" href="https://uat.fitsib.in" />
        <link rel="dns-prefetch" href="https://uat-cms.fitsib.in" />
      </head>

      <body>
        <Provider store={store}>
          <SkeletonTheme>
            <LanguageWrapper>
              {/* <Navbar /> */}
              {children}
              {/* <Footer /> */}
            </LanguageWrapper>
            <Toaster position="top-right" />
            <ProgressBar
              options={{ showSpinner: false }}
              shallowRouting
            />
          </SkeletonTheme>
        </Provider>
      </body>
    </html>
  );
}
